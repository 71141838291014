
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({
  components: {}
})
export default class extends Vue {
  @Action("moneyforward/getToken")
  public getToken!: (code: string) => Promise<boolean>;

  @Action("moneyforward/createRefreshToken")
  public createRefreshToken!: () => Promise<boolean>;

  // パラメータ定義
  public code = "";

  public async created() {
    this.queryToParams();
    await this.getToken(this.code);
    await this.createRefreshToken();
    this.$router.push(`/admin/home`);
  }

  // urlからパラメータをセットする
  private queryToParams() {
    const query = this.$route.query;
    this.code = query.code ? decodeURI(query.code as string) : "";
  }
}
